// material-ui
import { withStyles } from "@material-ui/core/styles";
import * as Actions from "actions";
// components
import App from "components/App";
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// styles
import styles from "./styles";

const mapStateToProps = (state) => ({
  user: state.user,
  student: state.myStudent,
  timezones: state.timezones,
});

const mapDispatchToProps = (dispatch) => ({
  getEventTypeById: (...args) => dispatch(Actions.getEventTypeById(...args)),
  getCoachById: (...args) => dispatch(Actions.getCoachById(...args)),
  getAvailabilities: (...args) => dispatch(Actions.getAvailabilities(...args)),
  createEvent: (...args) => dispatch(Actions.createEvent(...args)),
  getCurrentStudent: (...args) => dispatch(Actions.getCurrentStudent(...args)),
  getTimezones: (...args) => dispatch(Actions.getTimezones(...args)),
  getDailyAvailabilities: (...args) =>
    dispatch(Actions.getDailyAvailabilities(...args)),
  getEventTypesPublicAvailabilities: (...args) =>
    dispatch(Actions.getEventTypesPublicAvailabilities(...args)),
});

class Root extends Component {
  static propTypes = {
    getEventTypeById: PropTypes.func,
    getCurrentStudent: PropTypes.func,
    createEvent: PropTypes.func,
    getAvailabilities: PropTypes.func,
    getEventTypesPublicAvailabilities: PropTypes.func,
    getDailyAvailabilities: PropTypes.func,
    student: PropTypes.object,
    user: PropTypes.object,
    getTimezones: PropTypes.func,
    timezones: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { location, getCurrentStudent, getTimezones } = this.props;
    const urlParams = getJsonFromUrl(window.location);
    getCurrentStudent();
    getTimezones();
    this.refreshInnerHeight();
    window.addEventListener("resize", this.refreshInnerHeight.bind(this));
    this.state = {
      urlParams,
      eventTypeID: Number(urlParams.eventTypeID),
      coachID: Number(urlParams.coachID),
      teacherID: Number(urlParams.teacherID),
      studentID: Number(urlParams.studentID),
      loading: true,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  refreshInnerHeight() {
    const vh = window.innerHeight * 0.01;
    this.setState({ vh });
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  async refresh() {
    const { eventTypeID, coachID, teacherID } = this.state;
    const { getEventTypeById, getCoachById, getAvailabilities } = this.props;

    const resps = await Promise.all([
      getEventTypeById(eventTypeID),
      getCoachById(coachID),
      getAvailabilities(
        `[{"name":"eventTypeID","comparison":"eq","value":"${eventTypeID}"}, {"name":"coachID","comparison":"eq","value":"${coachID}"}]`
      ),
    ]);

    let resp;
    if (teacherID) {
      resp = await getCoachById(teacherID);
    }

    this.setState({
      eventType: resps[0].payload,
      coach: resps[1].payload,
      teacher: resp && resp.payload,
      availabilities: resps[2].payload,
      refreshKey: new Date().getTime(),
      loading: false,
    });
  }

  render() {
    const {
      location,
      history,
      user,
      student,
      createEvent,
      getEventTypesPublicAvailabilities,
      getDailyAvailabilities,
      timezones,
    } = this.props;

    const {
      urlParams,
      eventType,
      coach,
      teacher,
      loading,
      availabilities,
      coachID,
      studentID,
      teacherID,
      refreshKey,
    } = this.state;
    return (
      <div className="root">
        <App
          history={history}
          location={location}
          urlParams={urlParams}
          eventType={eventType}
          studentID={studentID}
          availabilities={
            availabilities &&
            availabilities.filter((a) => {
              if (teacher) {
                return a.coachID === coachID || a.coachID === teacherID;
              }
              return a.coachID === coachID;
            })
          }
          getDailyAvailabilities={getDailyAvailabilities}
          getEventTypesPublicAvailabilities={getEventTypesPublicAvailabilities}
          createEvent={createEvent}
          student={student}
          refreshKey={refreshKey}
          refresh={this.refresh.bind(this)}
          coach={coach}
          teacher={teacher}
          user={user}
          timezones={timezones}
          loading={loading}
        />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Root));
