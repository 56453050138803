import { combineReducers } from "redux"; //eslint-disable-line
import app from "./app";
import myStudent from "./myStudent";
import timezones from "./timezones";
import user from "./user";
export default combineReducers({
  user,
  app,
  myStudent,
  timezones,
});
