import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// material-ui
import { withStyles } from "@material-ui/styles";
// config
import { REACT_APP_LOGO } from "config";
import PropTypes from "prop-types";
import React from "react";
import Div100vh from "react-div-100vh";
// styles
import styles from "./styles";

class Loading extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    message: PropTypes.string,
  };

  render() {
    const { classes, message } = this.props;

    return (
      <Div100vh>
        <Grid
          container
          className={classes.root}
          alignContent="center"
          alignItems="center"
        >
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <img src={REACT_APP_LOGO} style={{ width: 300 }} />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              textAlign: "center",
              marginTop: 40,
            }}
          >
            <CircularProgress color="primary" />
            <Typography variant="caption" style={{ color: "white" }}>
              {message}
            </Typography>
          </Grid>
        </Grid>
      </Div100vh>
    );
  }
}

export default withStyles(styles)(Loading);
